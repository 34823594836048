import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Advancement from '../classes/Advancement';

type ProgressionState = {
  isLoading: boolean;
  progressions: Advancement[];
  activeProgression: Advancement | undefined;
  isOnboarded: boolean;
  hasFirstLoaded: boolean;
};

const initialState: ProgressionState = {
  isLoading: false,
  progressions: [],
  activeProgression: undefined,
  isOnboarded: true,
  hasFirstLoaded: false,
};

const progressionsSlice = createSlice({
  name: 'progressions',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    getProgressionSuccess(state, action: PayloadAction<Advancement[]>) {
      state.progressions = action.payload;
      state.activeProgression = action.payload.find((p) => p.isActive);
      state.isOnboarded = !!action.payload.length;
      state.hasFirstLoaded = true;
    },
    clearProgression(state) {
      state = Object.assign(state, initialState);
    },
  },
});

export const { setLoading, getProgressionSuccess, clearProgression } =
  progressionsSlice.actions;

export default progressionsSlice.reducer;
