import User from '../classes/User';

interface AppState {
  user: {
    isLoading: boolean;
    currentUser?: User;
    observedUser?: User;
    observedUserProgressions?: any[];
    all: User[];
    error?: string;
  };
  trainings: {
    isLoading: boolean;
    allLoading: boolean;
    current: any;
    trainings: any[];
    error: string;
  };
  videos: {
    isLoading: boolean;
    videos: any[];
    error: string;
  };
  sessions: {
    isLoading: boolean;
    sessions: any[];
    error: string;
  };
  programs: {
    isLoading: boolean;
    current: any;
    programs: any[];
    error: string;
  };
  weeks: {
    isLoading: boolean;
    weeks: any[];
    error?: string;
  };
  files: {
    isLoading: boolean;
    files: any[];
    error?: string;
  };
  content: {
    contentsLoading: boolean;
    contents: any[];
    currentContentLoading: boolean;
    currentContent: any;
    currentSectionLoading: boolean;
    currentSection?: any;
    currentChapterLoading: boolean;
    currentChapter: any;
    errors?: string[];
    message?: string;
  };
  subscriptions: {
    isLoading: boolean;
    subscriptions: any[];
    hasFirstLoaded: boolean;
  };
  progressions: {
    isLoading: boolean;
    progressions: any[];
    activeProgression?: any;
    isOnboarded: boolean;
    hasFirstLoaded: boolean;
  };
}

const initialState: AppState = {
  user: {
    isLoading: false,
    currentUser: null,
    observedUser: null,
    observedUserProgressions: [],
    all: [],
  },
  trainings: {
    isLoading: false,
    allLoading: false,
    current: null,
    trainings: [],
    error: null,
  },
  videos: {
    isLoading: false,
    videos: [],
    error: null,
  },
  sessions: {
    isLoading: false,
    sessions: [],
    error: null,
  },
  programs: {
    isLoading: false,
    current: null,
    programs: [],
    error: null,
  },
  weeks: {
    isLoading: false,
    weeks: [],
    error: null,
  },
  files: {
    isLoading: false,
    files: [],
    error: null,
  },
  content: {
    contentsLoading: false,
    contents: [],
    currentContentLoading: false,
    currentContent: null,
    currentSectionLoading: false,
    currentSection: null,
    currentChapterLoading: false,
    currentChapter: null,
    errors: [],
  },
  subscriptions: {
    isLoading: false,
    subscriptions: [],
    hasFirstLoaded: false,
  },
  progressions: {
    isLoading: false,
    progressions: [],
    activeProgression: null,
    isOnboarded: true,
    hasFirstLoaded: false,
  },
};

export default initialState;
