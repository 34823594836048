import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Subscription from './subscription.model';

type SubscriptionsState = {
  isLoading: boolean;
  subscriptions: Subscription[];
  hasFirstLoaded: boolean;
};

const initialState: SubscriptionsState = {
  isLoading: false,
  subscriptions: [],
  hasFirstLoaded: false,
};

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    getSubscriptionsSuccess(state, action: PayloadAction<Subscription[]>) {
      state.subscriptions = action.payload;
      state.hasFirstLoaded = true;
    },
    clearSubscriptions(state) {
      state = Object.assign(state, initialState);
    },
  },
});

export const { setLoading, getSubscriptionsSuccess, clearSubscriptions } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
