import React, { FC, useEffect, useRef, useState } from "react";
import useSearchParams from "../../hooks/useSearchParams";
import clsx from "clsx";

import { useForm } from "react-hook-form";
import { Instance } from "../../classes/API/API";
import { ExclamationCircleIcon, ExclamationTriangleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

const Forms = () => {

 
    const {fields, page, theme, show_logs, redirect_after, utm_source, utm_medium, utm_campaign, utm_content, utm_term, button_text, cio_id} = useSearchParams<{fields: string, page: string, theme: 'blue' | 'white', show_logs: string, redirect_after: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_content?: string, utm_term?: string, button_text?: string, cio_id?: string}>();

    const [formFields, setFormFields] = useState<Array<string> | undefined>(undefined);
    const [formTheme, setFormTheme] = useState<'blue' | 'white'>('blue');
    const [checkResponse, setCheckResponse] = useState<CheckAccountResponse | undefined>();

    const [enabled, setEnabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, formState: {errors}, watch, setValue} = useForm();

    const email = watch('email');

    const timeout = useRef<NodeJS.Timeout | null>(null);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if(fields) {
            setFormFields(fields.split(','));
        }
    }, [fields]);

    useEffect(() => {
        if(theme) {
            setFormTheme(theme);
        }
    }, [theme]);

    useEffect(() => {
        $crisp.push(['do', 'chat:hide']);
    }, [])

    useEffect(() => {
      setValue('utm_source', utm_source);
      setValue('utm_medium', utm_medium);
      setValue('utm_campaign', utm_campaign);
      setValue('utm_content', utm_content);
      setValue('utm_term', utm_term);
      setValue('cio_id', cio_id);
      setValue('page', page);
    }, [utm_source, utm_medium, utm_campaign, utm_content, utm_term, cio_id])

    useEffect(() => {
        if(email) {
            checkEmail();
        }
    }, [email])

    const onSubmit = async (data: any) => {
        console.log({...data});

        // TODO: Submit the form
        const res = await Instance(false).request.post('/v1/users/form-optin', {...data});

        if (redirect_after) {
            try {
                const decodedUrl = decodeURIComponent(redirect_after);
                window.parent.postMessage(
                  { action: "redirect", url: decodedUrl },
                  "*"
                );
            } catch (e) {
                console.error('Failed to decode redirect URL:', e);
            }
        }

    }

    const redirectToLogin = async () => {
      
      window.parent.postMessage(
        { action: "redirect", url: "https://app.resterjeune.com/se-connecter" },
        "*"
      );
          
    }

    const CheckResponses: FC = () => {

      if (!checkResponse) {
          return <div />;
      } else if (checkResponse.error) {
        setEnabled(false);
        return (
          <span className={'font-medium text-red-600 flex gap-1 items-center'}>
            <ExclamationTriangleIcon className={'w-5 h-5'} />{' '}
            {checkResponse.error} - <span className="font-bold text-teal-600" onClick={redirectToLogin}>Connectez-vous avec votre email en cliquant ici</span>
          </span>
        );
      } else if (checkResponse.data.suggestion) {
        setEnabled(true);
        return (
          <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
            <ExclamationCircleIcon className={'w-5 h-5'} /> Hum, ne serait-ce pas
            plutôt{' '}
            <strong>
              {checkResponse.data.local}@{checkResponse.data.suggestion}
            </strong>{' '}
            ?
          </span>
        );
      } else if (
        checkResponse.data.checks.domain.is_suspected_disposable_address
      ) {
        setEnabled(false);
        return (
          <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
            <ExclamationTriangleIcon className={'w-5 h-5 shrink-0'} /> Aïe, il
            semblerait que ce soit une addresse email jetable. Vous ne pouvez pas
            vous inscrire avec cette addresse !
          </span>
        );
      } else if (
        checkResponse.data.verdict === 'Risky' ||
        checkResponse.data.verdict === 'Invalid'
      ) {
        setEnabled(false);
        return (
          <span className={'font-medium text-orange-400 flex gap-1 items-center'}>
            <ExclamationCircleIcon className={'w-5 h-5 shrink-0'} /> Attention,
            vous pourriez avoir des difficultés à recevoir nos emails.
          </span>
        );
      } else {
        setEnabled(true);
        return <div />;
      }
    };

    const checkEmail = async () => {
        if(timeout.current) {
            clearTimeout(timeout.current);
        }
        setLoading(true);
        timeout.current = setTimeout(async () => {
            
            try {
                const { data } = await Instance(false).request.get<CheckAccountResponse>(
                  `/v1/users/check-account`,
                  {
                    params: {
                      email: email,
                      source: 'Optin Form - Page :' + page,
                    },
                  },
                );
                setCheckResponse(data);
              } catch (error) {
                if (error.response.status === 409) {
                  setCheckResponse({
                    success: false,
                    error: 'Cet utilisateur a déjà un compte sur Rester Jeune',
                    data: undefined
                  });
                }
              }
              setLoading(false);
        }, 1000);

    }

    return (
      <form className={clsx('max-w-3xl mx-auto p-4 gap-3 flex flex-col font-montserrat')} onSubmit={handleSubmit(onSubmit)}>
          {
          formFields?.includes('fullname') && (
              <div className={clsx("flex flex-col gap-2", formTheme === 'blue' ? 'text-white' : 'text-teal-600')}>
                  <h2>Prénom</h2>
                  <input {...register('fullname', {required: true})} type="text" autoComplete="name" className={clsx("w-full p-2 border border-gray-300 text-black rounded-md")} placeholder="Jeannine Dupont" />
                  {errors.fullname && <p className="text-red-500 text-sm">Vous devez entrer un nom</p>}
              </div>
        )}

        {
          formFields?.includes('email') && (
              <div className={clsx("flex flex-col gap-2", formTheme === 'blue' ? 'text-white' : 'text-teal-600')}>
                  <h2>Email</h2>
                  <input {...register('email', {required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/})} type="email" autoComplete="email" placeholder="jeannine.dupont@gmail.com" className="w-full p-2 border border-gray-300 rounded-md text-black" />
                  {errors.email && <p className="text-red-500 text-sm">Vous devez entrer une adresse email valide</p>}
                  <CheckResponses />
              </div>
          )
        }

        {
          formFields?.includes('password') && (
              <div className={clsx("flex flex-col gap-2", formTheme === 'blue' ? 'text-white' : 'text-teal-600')}>
                  <h2> Choisissez un mot de passe </h2>
                  <div className="flex gap-2 w-full border border-gray-300 rounded-md bg-white">
                      <input 
                      {...register('password', {required: true})} 
                      type={showPassword ? "text" : "password"} 
                      autoComplete="password" 
                      placeholder={!showPassword ? "•••••••••••••••••••••••••" : "Mon-M0t-De-P4sse-S3cret"} 
                      className={clsx("flex-1 border-none outline-none ring-0 hover:ring-0 focus:ring-0", formTheme === 'blue' ? 'text-black' : 'text-gray-600')} 
                      />
                      <button type="button" className="bg-transparent text-neutral-400 hover:text-neutral-600 px-2 rounded-md" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeSlashIcon className="w-4 h-4" /> : <EyeIcon className="w-4 h-4" />}</button>
                  </div>
                  {errors.password && <p className="text-red-500 text-sm">Vous devez entrer un mot de passe</p>}
              </div>
          )
        }

  {
          formFields?.includes('birthday') && (
              <div className={clsx("flex flex-col gap-2", formTheme === 'blue' ? 'text-white' : 'text-teal-600')}>
                  <h2> Date de naissance </h2>
                  <input 
                  {...register('birthday')} 
                  type="date" 
                  autoComplete="birthday" 
                  placeholder={'jj/mm/aaaa'} 
                  className={clsx("gap-2 w-full border border-gray-300 rounded-md flex-1 outline-none ring-0 hover:ring-0 focus:ring-0", formTheme === 'blue' ? 'text-black' : 'text-gray-600')}
                  max={new Date(new Date().setFullYear(new Date().getFullYear() - 25)).toISOString().split('T')[0]}
                  />
              </div>
          )
        }


        {
          formFields?.includes('terms') && (
              <div className={clsx("flex flex-col gap-2", formTheme === 'blue' ? 'text-white' : 'text-teal-600')}>
                  <div className="flex items-center gap-2">
                      <input {...register('terms', {required: true})} type="checkbox" autoComplete="terms" className="w-4 p-2 border border-gray-300 rounded-md text-teal-600" />
                      <p> J'accepte les conditions d'utilisation </p>
                  </div>
                  {errors.terms && <p className="text-red-500 text-sm">Vous devez accepter les conditions d'utilisation</p>}
              </div>
          )
        }

        <div className="flex gap-2">
          <input {...register('page')} type={show_logs === "true" ? "text" : "hidden"} name="page" value={page} placeholder="page" className="w-full p-2 border border-gray-300 rounded-md placeholder:text-gray-400 placeholder:italic" />
          <input {...register('utm_source')} type={show_logs === "true" ? "text" : "hidden"} name="utm_source" value={utm_source} placeholder="utm_source" className="w-full p-2 border border-gray-300 rounded-md placeholder:text-gray-400 placeholder:italic" />
          <input {...register('utm_medium')} type={show_logs === "true" ? "text" : "hidden"} name="utm_medium" value={utm_medium} placeholder="utm_medium" className="w-full p-2 border border-gray-300 rounded-md placeholder:text-gray-400 placeholder:italic" />
          <input {...register('utm_campaign')} type={show_logs === "true" ? "text" : "hidden"} name="utm_campaign" value={utm_campaign} placeholder="utm_campaign" className="w-full p-2 border border-gray-300 rounded-md placeholder:text-gray-400 placeholder:italic" />
          <input {...register('utm_content')} type={show_logs === "true" ? "text" : "hidden"} name="utm_content" value={utm_content} placeholder="utm_content" className="w-full p-2 border border-gray-300 rounded-md placeholder:text-gray-400 placeholder:italic" />
          <input {...register('utm_term')} type={show_logs === "true" ? "text" : "hidden"} name="utm_term" value={utm_term} placeholder="utm_term" className="w-full p-2 border border-gray-300 rounded-md placeholder:text-gray-400 placeholder:italic" />
          <input {...register('cio_id')} type={show_logs === "true" ? "text" : "hidden"} name="cio_id" value={cio_id} placeholder="cio_id" className="w-full p-2 border border-gray-300 rounded-md placeholder:text-gray-400 placeholder:italic" />
        </div>
          
        
        <input disabled={!enabled && !loading} type="submit" className={clsx(
          "p-2 rounded-md mt-4 font-bold disabled:opacity-50", 
          formTheme === 'blue' ? 'bg-brightYellow-400 text-black' : 'bg-teal-600 text-white', 
          !enabled && 'bg-gray-400 text-gray-600')} value={button_text || 'S\'inscrire'} />

      </form>
    );    
};

export default Forms;
