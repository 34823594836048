import { useAppSelector } from './redux';
import User from '../classes/User';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../actions/user.action';
import { useEffect } from 'react';

const useUser = () => {
  const dispatch = useDispatch();

  const user = useAppSelector((store) => store.user.currentUser);
  const userError = useAppSelector((store) => store.user.error);

  const fetchUser = () => {
    dispatch(getCurrentUser(new User()));
  };

  return { user, fetchUser, userError };
};

export default useUser;
