import React, { FC, useEffect, useRef, useState } from "react";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Cookies from 'js-cookie';
import User from "../../classes/User";
import { useHistory } from 'react-router';
import logo_colored from '../../assets/animations/logo_colored.json';
import { ExclamationCircleIcon, ExclamationTriangleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import useSearchParams from "../../hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { clearUser } from "../../reducers/user.reducer";
import { clearSubscriptions } from "../../features/subscriptions/subscriptions.reducer";
import { clearProgram } from "../../reducers/programs.reducers";
import { clearProgression } from "../../reducers/progression.reducer";
import { clearSessions } from "../../reducers/sessions.reducers";
import login from "./login";
import { signup } from "../../actions/user.action";
import { Instance } from "../../classes/API/API";
import useUser from "../../hooks/useUser";
import { identifySegment } from "../../hooks/useSegment";
import { Event } from '../../constants/SegmentEvents';

const SignupPage = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [checkResponse, setCheckResponse] = useState<CheckAccountResponse | undefined>();
    const {user, userError} = useUser();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const {parrain, utm_source, utm_medium, utm_campaign, utm_content, utm_term, cio_id} = useSearchParams<{ parrain: string, utm_source: string, utm_medium: string, utm_campaign: string, utm_content: string, utm_term: string, cio_id: string }>();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<{ firstname: string, email: string, password: string, parrain: string, terms: boolean }>();
    const timeout = useRef<NodeJS.Timeout | null>(null);

    const email = watch('email');
    
    useEffect(() => {
        if(parrain) {
            setValue('parrain', parrain);
        }
    }, [parrain]);

    useEffect(() => {
        if(email) {
            checkEmail();
        }
    }, [email])

    useEffect(() => {
        if(user) {
            identifySegment(user);
            Event.SIGN_UP.track();
            history.push('/');
        }
    }, [user])


    const clearValues = () => {
        dispatch(clearUser());
        dispatch(clearProgram());
        dispatch(clearSubscriptions());
        dispatch(clearProgression());
        dispatch(clearSessions());
    }

    const handleSignUp = (data: any) => {

      const metadata = {
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          utm_term,
          cio_id,
          page: 's-inscrire'
      }

      

        setIsLoading(true);
        clearValues();
        const user = new User();
        user.email = data.email;
        user.password = data.password;
        user.confirmPassword = data.password;
        user.firstname = data.firstname;
        user.sponsor = data.parrain;
        
        setTimeout(() => {
            dispatch(signup(user, metadata));
            setIsLoading(false);
        }, 1000);
    }

    const checkEmail = async () => {
        if(timeout.current) {
            clearTimeout(timeout.current);
        }
        setIsLoading(true);
        timeout.current = setTimeout(async () => {
            
            try {
                const { data } = await Instance(false).request.get<CheckAccountResponse>(
                  `/v1/users/check-account`,
                  {
                    params: {
                      email: email,
                      source: 'Signup Form',
                    },
                  },
                );
                setCheckResponse(data);
              } catch (error) {
                if (error.response.status === 409) {
                  setCheckResponse({
                    success: false,
                    error: 'Cet utilisateur a déjà un compte sur Rester Jeune',
                    data: undefined
                  });
                }
              }
              setIsLoading(false);
        }, 1000);

    }

    const CheckResponses: FC = () => {

        if (!checkResponse) {
            return <div />;
        } else if (checkResponse.error) {
          setEnabled(false);
          return (
            <span className={'font-medium text-red-600 flex gap-1 items-center text-sm'}>
              <ExclamationTriangleIcon className={'w-5 h-5'} />{' '}
              {checkResponse.error} - <span className="font-bold text-teal-600" onClick={redirectToLogin}>Connectez-vous avec votre email en cliquant ici</span>
            </span>
          );
        } else if (checkResponse.data.suggestion) {
          setEnabled(true);
          return (
            <span className={'font-medium text-orange-400 flex gap-1 items-center text-sm'}>
              <ExclamationCircleIcon className={'w-5 h-5'} /> Hum, ne serait-ce pas
              plutôt{' '}
              <strong>
                {checkResponse.data.local}@{checkResponse.data.suggestion}
              </strong>{' '}
              ?
            </span>
          );
        } else if (
          checkResponse.data.checks.domain.is_suspected_disposable_address
            ) {
            setEnabled(false);
          return (
            <span className={'font-medium text-orange-400 flex gap-1 items-center text-sm'}>
              <ExclamationTriangleIcon className={'w-5 h-5 shrink-0'} /> Aïe, il
              semblerait que ce soit une addresse email jetable. Vous ne pouvez pas
              vous inscrire avec cette addresse !
            </span>
          );
        } else if (
          checkResponse.data.verdict === 'Risky' ||
          checkResponse.data.verdict === 'Invalid'
        ) {
          setEnabled(false);
          return (
            <span className={'font-medium text-orange-400 flex gap-1 items-center text-sm'}>
              <ExclamationCircleIcon className={'w-5 h-5 shrink-0'} /> Attention,
              vous pourriez avoir des difficultés à recevoir nos emails.
            </span>
          );
        } else {
          setEnabled(true);
          return <div />;
        }
      };

      const redirectToLogin = async () => {
      
        history.push('/se-connecter');
            
      }
    
    return (
        <div className="flex flex-1 flex-col items-center justify-center h-screen bg-gray-100 font-montserrat">
            <div className={'flex-1'}/>
            <div className="flex flex-col items-center justify-center bg-white p-8 rounded-md shadow-md gap-6 xs:w-full sm:w-5/6 md:w-1/2">
            <a href="https://www.resterjeune.com" target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center">
                        <DotLottieReact
                            data={logo_colored}
                            autoplay
                            width={250}
                            height={100}
                        className="w-1/2"
                    />
                    </a>
                
                <h1 className="text-2xl font-medium">
                    {(() => {
                        const hour = new Date().getHours();
                        if (hour >= 0 && hour < 13) return '🌞 Bonjour !';
                        if (hour >= 13 && hour < 18) return '🌤️ Bon après-midi !';
                        if (hour >= 18 && hour < 22) return '🌃 Bonne soirée !';
                        return '🌙 Bonne nuit !';
                    })()}
                </h1>
                <h1 className="text-xl font-medium">
                    Inscrivez-vous et bénéficiez de de notre Challenge Gratuit !
                </h1>
                
                <form onSubmit={handleSubmit(handleSignUp)} className="w-full flex flex-col gap-4">
                <div className="flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400">
                    <label htmlFor="firstname" className="text-sm font-base font-montserrat text-gray-500">Votre prénom <span className="text-red-500">*</span> {errors.firstname && <span className="text-red-500">{errors.firstname.message}</span>}</label>
                    <input type="text" {...register('firstname', { required: {value: true, message: 'Le prénom est requis'} })} placeholder="Julien" autoComplete="name" className="capitalize w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0" />
                </div>

                <div className="flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400">
                    <label htmlFor="email" className="text-sm font-base font-montserrat text-gray-500">Votre adresse email <span className="text-red-500">*</span> {errors.email && <span className="text-red-500">{errors.email.message}</span>}</label>
                    <input type="email" {...register('email', { required: {value: true, message: 'L\'adresse email est requise'} })} placeholder="julien@resterjeune.com" autoComplete="email" className="lowercase w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0" />
                </div>
                <CheckResponses />

                <div className="flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400">
                    <label htmlFor="password" className="text-sm font-base font-montserrat text-gray-500">Votre mot de passe <span className="text-red-500">*</span> {errors.password && <span className="text-red-500">{errors.password.message}</span>}</label>
                    <div className="relative">
                        <input type={showPassword ? "text" : "password"} {...register('password', { required: {value: true, message: 'Le mot de passe est requis'} })} placeholder={showPassword ? "MonM0tD3P4sse" : "••••••••••••"} autoComplete="new-password" className="lowercase w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0" />
                        <button className="absolute right-0 top-0 bottom-0"  onClick={(e) => {
                                e.preventDefault();
                                setShowPassword(!showPassword);
                            }}>
                            {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                        </button>
                    </div>
                    
                </div>

                {parrain && <div className="flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400">
                    <label htmlFor="sponsor" className="text-sm font-base font-montserrat text-gray-500">Votre code parrain</label>
                    <div className="relative">
                        <input type="text" {...register('parrain', {required: false})} placeholder="MonCodeParrain" className="uppercase font-plexmono w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0" />
                    </div>
                    
                </div>}

                <div className="flex items-center gap-2 w-full focus-within:border-teal-400">
                    <input type="checkbox" id="terms" {...register('terms', {required: true})} className="w-5 h-5 rounded-md border-gray-300 text-teal-600 focus:border-none hover:ring-0 focus:ring-0" />
                    <label htmlFor="terms" className="text-sm font-base font-montserrat text-gray-500 inline-flex flex-wrap gap-1">En m'inscrivant, j'accepte
              <a
                href={'/legal/cgv'}
                className={
                  'font-medium underline decoration-teal-600 underline-offset-2'
                }
              >
                les CGVU
              </a>
              et
              <a
                href={'/legal/privacy'}
                className={
                  'font-medium underline underline-offset-2 decoration-teal-600'
                }
              >
                la Politique de confidentialité
              </a>
              de Rester Jeune.<span className="text-red-500">*</span> {errors.terms && <span className="text-red-500">{errors.terms.message}</span>}</label>
                </div>


                <button type="submit" disabled={!enabled || isLoading} className="flex items-center justify-center gap-2 w-full bg-teal-600 text-white p-4 rounded-md shadow disabled:opacity-50 disabled:cursor-not-allowed">
                    {isLoading && (
                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"/>
                    )}
                    <div className="font-medium">Créer mon compte</div>
                </button>
                </form>
                <hr className="w-full border-gray-300" />

                <span className="text-sm font-base font-montserrat text-gray-500 w-full flex items-center justify-center gap-2">
                    <span className="font-medium">J'ai déjà un compte</span> -
                    <Link to="/se-connecter" className="text-teal-600 font-medium">Se connecter</Link>
                </span>
            
                
            </div>
            <div className={'flex-1'}/>
            <footer className="w-full flex items-center justify-center gap-2 text-sm font-base font-montserrat text-gray-400 self-end my-4">
                © 2020 - {new Date().getFullYear()} - Tous droits réservés RJFitness SAS & <a href="https://bitsncoffee.fr?utm_source=rjfitness&utm_medium=footer&utm_campaign=rjfitness" target="_blank" rel="noopener noreferrer">Bits'n Coffee</a>
            </footer>
        </div>
    )

}

export default SignupPage;