import React, { useEffect, useState } from "react";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Cookies from 'js-cookie';
import User from "../../classes/User";
import { useHistory } from 'react-router';
import logo_colored from '../../assets/animations/logo_colored.json';

const AutoLoginPage = () => {

    const [lottie, setLottie] = useState<ArrayBuffer | null>(null);

    const arrayBuffer = async () => {
        const response = await fetch('../../assets/animations/logo_colored.lottie');
        const arrayBuffer = await response.arrayBuffer();
        console.log(arrayBuffer);
        setLottie(arrayBuffer);
    }

    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState('');
    const [tokenData, setTokenData] = useState<Record<string, any> | null>(null);
    const history = useHistory();

    useEffect(() => {
        getTokenData();
    }, [token]);

    useEffect(() => {
        arrayBuffer().then(() => {})
        const token = Cookies.get('_rj_auth_token');
        if (token) {
            setToken(token);
            const user = new User();
            user.storeJwt(token);
            setTimeout(async () => {
                const u = await user.getCurrent();
                if (u) {
                    history.push('/');
                }
            }, 3000);
        }
    }, []);

    const getTokenData = async () => {
        if (token) {
            const tokenParts = token.split('.');
            const payload = JSON.parse(atob(tokenParts[1]));
            setTokenData(payload);
        }
    }
    
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="flex flex-col items-center justify-center bg-white p-8 rounded-md shadow-md gap-2">
                    <DotLottieReact
                        data={logo_colored}
                        autoplay
                        width={150}
                        height={100}
                        className="w-1/2"
                    />
                
                <h1 className="text-2xl font-bold">
                    {(() => {
                        const hour = new Date().getHours();
                        if (hour >= 0 && hour < 13) return 'Bonjour ';
                        if (hour >= 13 && hour < 18) return 'Bonne après-midi ';
                        if (hour >= 18 && hour < 22) return 'Bonne soirée ';
                        return 'Bonne nuit ';
                    })()}
                    {tokenData?.firstname} {tokenData?.lastname}</h1>
                <p className="text-gray-500">
                    {token ? 'Vous allez être redirigé vers votre compte dans quelques secondes.' : 'Une erreur est survenue lors de la connexion automatique. Veuillez vous reconnecter.'}
                </p>
                <p className="text-gray-500">
                    {!token && <button className="bg-teal-600 text-white px-4 py-2 rounded-md" onClick={() => history.push('/se-connecter')}>Se connecter</button>}
                </p>
                {isLoading && (
                    <div className="flex items-center justify-center mt-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-teal-600"></div>
                    </div>
                )}
            </div>
        </div>
    )

}

export default AutoLoginPage;