import User from '../classes/User';
import {
  startLoading,
  signInSuccess,
  userActionFailure,
  signUpSuccess,
  getSuccess,
  findOneSuccess,
  getAllSuccess,
  clearErrors,
  clearUser,
  findProgressionsSuccess,
} from '../reducers/user.reducer';
import { AppThunk } from '../store';
import { ProgramLevel } from '../classes/Program';
import Advancement from '../classes/Advancement';
import { loadProgressionAction } from './progressions.action';

export const login =
  (user: User, metadata?: Record<string, any>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      dispatch(clearErrors());
      dispatch(clearUser());
      
      // Create a fresh user instance to avoid any stale data
      const freshUser = new User();
      freshUser.email = user.email;
      freshUser.password = user.password;
      freshUser.googleId = user.googleId;
      freshUser.facebookId = user.facebookId;
      freshUser.apple_id = user.apple_id;
      freshUser.metadata = metadata;
      await freshUser.signIn();
      await freshUser.getCurrent();
      dispatch(loadProgressionAction(freshUser));
      dispatch(signInSuccess(freshUser));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const signup =
  (user: User, metadata?: Record<string, any>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearErrors());
      dispatch(startLoading());
      dispatch(clearUser());
      user.metadata = metadata;
      await user.signUp();
      await user.getCurrent();
      dispatch(signUpSuccess(user));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const onboard =
  (data: Record<string, any>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(clearErrors());
      dispatch(startLoading());
      dispatch(clearUser());
      await User.optin(data);
      dispatch(getCurrentUser(new User()));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const getAll = (): AppThunk => async (dispatch) => {
  try {
    dispatch(startLoading());
    const users = await User.getAll(0);
    dispatch(getAllSuccess(users));
  } catch (error) {
    dispatch(userActionFailure(error.message));
  } finally {
    dispatch(startLoading());
  }
};

export const updateProfile =
  (user: User, current = true): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await user.update();
      if (current) {
        dispatch(getCurrentUser(user));
      } else {
        dispatch(getUserProfile(user.hash, false));
      }
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const updatePasswordAction =
  (user: User, admin?: string | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await user.changePassword(admin);
      await user.getCurrent();
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const createTraining =
  (user: User, answers: any[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await user.createTraining(answers);
      dispatch(getCurrentUser(user));
      dispatch(loadProgressionAction(user));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const updateTraining =
  (user: User, level: ProgramLevel): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await user.updateTraining(level);
      dispatch(getCurrentUser(user));
      dispatch(loadProgressionAction(user));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const updateProgression =
  (user: User, advancement: Advancement): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await advancement.update(user);
      dispatch(getUserProfile(user.hash, false));
      dispatch(fetchProgressions(user, false));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const getCurrentUser =
  (user: User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      dispatch(clearUser());
      await user.getCurrent();
      dispatch(getSuccess(user));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const getUserProfile =
  (userHash: string, small: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      const user = await User.getOne(userHash, small);
      dispatch(findOneSuccess(user as User));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const validateUser =
  (user: User, token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await user.validate(token);
      dispatch(getCurrentUser(user));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const requestNewPassword =
  (user: User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      await user.requestNewPassword();
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const resetPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const fetchProgressions =
  (user: User, getActive = false): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startLoading());
      const progressions = await Advancement.get(user, getActive, false);
      dispatch(findProgressionsSuccess(progressions));
    } catch (error) {
      dispatch(userActionFailure(error.message));
    } finally {
      dispatch(startLoading());
    }
  };

export const generateError =
  (error: string): AppThunk =>
  async (dispatch) => {
    dispatch(userActionFailure(error));
  };
