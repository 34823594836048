import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const SegmentAnalytics = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.analytics.page();
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default withRouter(SegmentAnalytics);
