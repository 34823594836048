import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
const TOKEN_KEY = '@RJF::ApiToken';

const { CancelToken } = axios;

const Instance = (authenticated: boolean | 'maybe' = false) => {
  let cancel;
  let apiToken = window.localStorage.getItem(TOKEN_KEY);

  // Decode the token
  if(apiToken) {
    const decodedToken = jwtDecode(apiToken);
    console.log(decodedToken.exp);
    if(decodedToken.exp < Date.now() / 1000) {
      window.localStorage.removeItem(TOKEN_KEY);
      apiToken = null;
      window.location.href = process.env.FRONT_URL + '/se-connecter?error=token_expired';
    }
  }

  const headers: any = {};

  if (authenticated === true && apiToken) {
    headers.Authorization = `Bearer ${apiToken}`;
  } else if (authenticated === true && !apiToken) {
    throw new Error('Vous n\'êtes pas / plus connecté(e)');
  } else if (authenticated === 'maybe') {
    if (apiToken) {
      headers.Authorization = `Bearer ${apiToken}`;
    }
  }

  headers['Content-Type'] = 'application/json';
  headers['Accept'] = 'application/json';
  headers['x-rj-source'] = 'web';
  headers['x-rj-version'] = '1.0.0';
  
  const request = axios.create({
    baseURL: process.env.API_URL,
    headers,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
  });

  /*request.interceptors.response.use((response) => {
    if (response.status === 401) {
      window.location.href =
        process.env.FRONT_URL + '/se-connecter?error=unauthenticated';
      return response;
    } else {
      return response;
    }
  });*/

  return { request, cancel };
};

export { Instance, TOKEN_KEY };
