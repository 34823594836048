import { combineReducers } from 'redux';
import initialState from '../store/initialState';

// Reducers
import user from './user.reducer';
import trainingReducer from './trainings.reducers';
import videoReducer from './videos.reducers';
import sessionReducer from './sessions.reducers';
import programReducer from './programs.reducers';
import weekReducer from './weeks.reducers';

import files from './file.reducer';

import subscriptions from '../features/subscriptions/subscriptions.reducer';
import progressions from './progression.reducer';
import content from '../features/content/content.reducer';

const rootReducer = combineReducers({
  user,
  trainings: trainingReducer,
  videos: videoReducer,
  sessions: sessionReducer,
  programs: programReducer,
  weeks: weekReducer,
  files: files,
  content: content,
  subscriptions,
  progressions,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
