import React, { useEffect, useState } from "react";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Cookies from 'js-cookie';
import User from "../../classes/User";
import { useHistory } from 'react-router';
import logo_colored from '../../assets/animations/logo_colored.json';
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearErrors, clearUser } from "../../reducers/user.reducer";
import { clearProgram } from "../../reducers/programs.reducers";
import { clearSubscriptions } from "../../features/subscriptions/subscriptions.reducer";
import { clearProgression } from "../../reducers/progression.reducer";
import { clearSessions } from "../../reducers/sessions.reducers";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { login } from "../../actions/user.action";
import useUser from "../../hooks/useUser";
import { UserRole } from "../../classes/enums/UserRole";
import useSearchParams from "../../hooks/useSearchParams";

const LoginPage = () => {

    const [showPassword, setShowPassword] = useState(false);
    const { error, redirect, utm_source, utm_medium, utm_campaign, utm_content, utm_term } = useSearchParams<{error?: string, redirect?: string, utm_source?: string, utm_medium?: string, utm_campaign?: string, utm_content?: string, utm_term?: string}>();

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors } } = useForm<{ email: string, password: string }>();
    const { user, userError } = useUser();

    useEffect(() => {
        console.log('user', user);
        if(user) {
            setIsLoading(false);
            let url = `/`;
            switch (user.role) {
                case UserRole.banned:
                  url = `/se-connecter?error=banned`;
                  break;
                default:
                    url = `/`;
                    break;
              }
              history.push(url);
        }
    }, [user]);

    useEffect(() => {
        console.log('userError', userError);
        if(userError) {
            setIsLoading(false);
        }
    }, [userError]);


    const clearValues = () => {
        dispatch(clearUser());
        dispatch(clearProgram());
        dispatch(clearSubscriptions());
        dispatch(clearProgression());
        dispatch(clearSessions());
    }

    const handleSignIn = (data: any) => {

        const metadata = {
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
            page: 's-inscrire'
        }

        setIsLoading(true);
        clearValues();
        const user = new User();
        user.email = data.email;
        user.password = data.password;
        setTimeout(() => {
            dispatch(login(user, metadata));
            setIsLoading(false);
        }, 2000);
    }



    
    return (
        <div className="flex flex-1 flex-col items-center justify-center h-screen bg-gray-100 font-montserrat">
            <div className={'flex-1'}/>
            <div className="flex flex-col items-center justify-center bg-white p-8 rounded-md shadow-md gap-6 xs:w-full sm:w-5/6 md:w-1/2">
                    <a href="https://www.resterjeune.com" target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center">
                        <DotLottieReact
                            data={logo_colored}
                            autoplay
                            width={250}
                            height={100}
                        className="w-1/2"
                    />
                    </a>
                
                <h1 className="text-2xl font-medium">
                    {(() => {
                        const hour = new Date().getHours();
                        if (hour >= 0 && hour < 13) return '🌞 Bonjour !';
                        if (hour >= 13 && hour < 18) return '🌤️ Bon après-midi !';
                        if (hour >= 18 && hour < 22) return '🌃 Bonne soirée !';
                        return '🌙 Bonne nuit !';
                    })()}
                </h1>
            
                <form onSubmit={handleSubmit(handleSignIn)} className="w-full flex flex-col gap-4">

                    {userError && (
                        <div className="bg-red-100 border-red-500  border text-red-500 p-2 rounded-md w-full flex items-center justify-center">
                            <p className="text-red-500 flex-1">{userError} </p><button onClick={() => dispatch(clearErrors())}>&times;</button>
                        </div>
                    )}

                    <div className={clsx("flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400", errors.email && "border-red-500")}>
                        <label htmlFor="email" className="text-sm font-base font-montserrat text-gray-500">Votre adresse email <span className="text-red-500">*</span> <span className="text-red-500">{errors.email && '- ' + errors.email.message}</span></label>
                        <input type="text" placeholder="julien@resterjeune.com" {...register('email', { required: { value: true, message: 'L\'adresse email est requise' } })} className="lowercase w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0" />
                    </div>

                    <div className={clsx("flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400", errors.password && "border-red-500")}>
                        <label htmlFor="password" className="text-sm font-base font-montserrat text-gray-500">Votre mot de passe <span className="text-red-500">*</span> <span className="text-red-500">{errors.password && '- ' + errors.password.message}</span></label>
                        <div className="relative">
                            <input type={showPassword ? "text" : "password"} placeholder={showPassword ? "MonM0tD3P4sse" : "••••••••••••"} {...register('password', { required: { value: true, message: 'Le mot de passe est requis' } })} autoComplete="current-password" className="w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0" />
                            <button className="absolute right-0 top-0 bottom-0" onClick={(e) => {
                                e.preventDefault();
                                setShowPassword(!showPassword);
                            }}>
                                {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                            </button>
                        </div>
                        
                    </div>

                    <button className="flex items-center justify-center gap-2 w-full bg-teal-600 text-white p-4 rounded-md shadow">
                        {isLoading && (
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"/>
                        )}
                        <div className="font-medium">Connexion</div>
                    </button>
                </form>
                
                

                <hr className="w-full border-gray-300" />

                <span className="text-sm font-base font-montserrat text-gray-500 w-full flex items-center justify-center gap-2">
                    <span className="font-medium">Mot de passe oublié ?</span>
                    <Link to="/oubli-mot-de-passe" className="text-teal-600 font-medium">Réinitialiser mon mot de passe</Link>
                </span>
                <span className="text-sm font-base font-montserrat text-gray-500 w-full flex items-center justify-center gap-2">
                    <span className="font-medium">Pas encore inscrit ?</span>
                    <Link to={`/s-inscrire${window.location.search}`} className="text-teal-600 font-medium">Créer un compte</Link>
                </span>
            
                
            </div>
            <div className={'flex-1'}/>
            <footer className="w-full flex items-center justify-center gap-2 text-sm font-base font-montserrat text-gray-400 self-end my-4">
                © 2020 - {new Date().getFullYear()} - Tous droits réservés RJFitness SAS & <a href="https://clementsauvage.me?utm_source=rjfitness&utm_medium=footer&utm_campaign=rjfitness" target="_blank" rel="noopener noreferrer">Bits'n Coffee</a>
            </footer>
        </div>
    )

}

export default LoginPage;