import React, { useEffect, useState } from "react";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Cookies from 'js-cookie';
import User from "../../classes/User";
import { useHistory } from 'react-router';
import logo_colored from '../../assets/animations/logo_colored.json';
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import useSearchParams from "../../hooks/useSearchParams";
import clsx from "clsx";
import { useForm } from "react-hook-form";

const SignupPage = () => {

    const [user, setUser] = useState(new User());
    const [loading, setLoading] = useState(false);
    const { email, token, reset } = useSearchParams<{email: string, token: string, reset: boolean}>();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<{ email: string, password: string }>();

    const [message, setMessage] = useState('');
    const history = useHistory();

    useEffect(() => {
        setValue('email', email);
    }, [email]);

    const requestPassword = async () => {
        setLoading(true);
        let res = await user.requestNewPassword();
        setMessage(res.data.message);
        setLoading(false);
      };
    
      const resetPassword = async () => {
        setLoading(true);
        await user.resetPassword(token);
        setMessage('Votre mot de passe a été réinitialisé avec succès. Vous allez être redirigé vers l\'application');
        setLoading(false);
        setTimeout(() => history.push('/mon-compte'), 1500);
      };

    const submitRequestPassword = async (data: { email: string, password: string }) => {
        user.email = data.email;
        if(reset) {
            user.password = data.password;
            user.confirmPassword = data.password;
            await resetPassword();
        } else {
            await requestPassword();
        }
    }



    
    return (
        <div className="flex flex-1 flex-col items-center justify-center h-screen bg-gray-100 font-montserrat">
            <div className={'flex-1'}/>
            <div className="flex flex-col items-center justify-center bg-white p-8 rounded-md shadow-md gap-6 xs:w-full sm:w-5/6 md:w-1/2">
                <a href="https://www.resterjeune.com" target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center">
                        <DotLottieReact
                            data={logo_colored}
                            autoplay
                            width={250}
                            height={100}
                        className="w-1/2"
                    />
                    </a>
                
                <h1 className="text-2xl font-medium">
                    {(() => {
                        const hour = new Date().getHours();
                        if (hour >= 0 && hour < 13) return '🌞 Bonjour !';
                        if (hour >= 13 && hour < 18) return '🌤️ Bon après-midi !';
                        if (hour >= 18 && hour < 22) return '🌃 Bonne soirée !';
                        return '🌙 Bonne nuit !';
                    })()}
                </h1>
                <h1 className="text-lg font-medium">
                    Pas de panique, nous vous envoyons un email pour réinitialiser votre mot de passe.
                </h1>
                
                <form onSubmit={handleSubmit(submitRequestPassword)} className="w-full flex flex-col gap-4">

                {message && (
                        <div className="bg-teal-100/30 border-teal-500 border text-teal-500 p-2 rounded-md w-full flex items-center justify-center text-sm">
                            <p className="text-teal-500">{message}</p>
                        </div>
                    )}


                <div className={clsx("flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400", reset && "bg-gray-100 cursor-not-allowed")}>
                    <label htmlFor="email" className="text-sm font-base font-montserrat text-gray-500">Votre adresse email</label>
                    <input type="email" {...register('email', { required: { value: true, message: 'L\'adresse email est requise' } })} disabled={reset} placeholder="jean.dupont@monemail.com" autoComplete="email" className="lowercase w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0 disabled:bg-gray-100" />
                </div>

                {reset && token.length > 0 && <div className="flex flex-col gap-2 w-full border rounded-md border-gray-300 p-2 focus-within:border-teal-400">
                    <label htmlFor="password" className="text-sm font-base font-montserrat text-gray-500">Choisissez votre nouveau mot de passe</label>
                    <div className="relative">
                        <input {...register('password', { required: { value: true, message: 'Le mot de passe est requis' } })} type={showPassword ? "text" : "password"} placeholder={showPassword ? "MonM0tD3P4sse" : "••••••••••••"} autoComplete="new-password" className="lowercase w-full border-none outline-none p-0 hover:border-none focus:border-none hover:shadow-none focus:shadow-none hover:ring-0 focus:ring-0" />
                        <button className="absolute right-0 top-0 bottom-0" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                        </button>
                    </div>
                    
                </div>}

                <button className="flex items-center justify-center gap-2 w-full bg-teal-600 text-white p-4 rounded-md shadow disabled:opacity-50" type="submit" disabled={loading}>
                    {loading && (
                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"/>
                    )}
                    <div className="font-medium">Réinitialiser mon mot de passe</div>
                </button>

                </form>

                <hr className="w-full border-gray-300" />

                <span className="text-sm font-base font-montserrat text-gray-500 w-full flex items-center justify-center gap-2">
                    <span className="font-medium">Je l'ai retrouvé !</span> -
                    <Link to="/se-connecter2" className="text-teal-600 font-medium">Se connecter</Link>
                </span>
            
                
            </div>
            <div className={'flex-1'}/>
            <footer className="w-full flex items-center justify-center gap-2 text-sm font-base font-montserrat text-gray-400 self-end my-4">
                © 2020 - {new Date().getFullYear()} - Tous droits réservés RJFitness SAS & <a href="https://bitsncoffee.fr?utm_source=rjfitness&utm_medium=footer&utm_campaign=rjfitness" target="_blank" rel="noopener noreferrer">Bits'n Coffee</a>
            </footer>
        </div>
    )

}

export default SignupPage;