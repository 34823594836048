import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../hooks/redux';

import contentfulClient, {
  richTextOptions,
} from '../../../utils/contentfulClient';
import {
  IOptinPageFields,
  IParagraph,
  ISignupForm,
  IThreeColumnsLayout,
  IVideoContent,
} from '../../../interfaces/contentfulModels';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import MuxContentfulVideoPlayer from '../../../components/MuxContentfulVideoPlayer';
import OptinForm from '../../../components/Optin/OptinForm';
import { Logo } from '../../../assets/img/icons';
import OptinParagraph from '../../../components/Optin/OptinParagraph';
import useSearchParams from '../../../hooks/useSearchParams';
import { Instance } from '../../../classes/API/API';
import Cookies from 'js-cookie';
import { addDays, addYears } from 'date-fns';
import ColumnLayout from '../../../components/Optin/ColumnLayout';

const optinStyleOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong className={'font-bold'}>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <>
          <a href={node.data.uri} target={'_blank'} rel={'noreferrer'}>
            {children}
          </a>
        </>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const contentId = node.data.target.sys.contentType?.sys.id;

      switch (contentId) {
        case 'video_content':
          const video = node.data.target as IVideoContent;
          return (
            <div className={'w-full md:w-2/3 mx-auto my-4'}>
              <MuxContentfulVideoPlayer video={video} />
            </div>
          );
        case 'signupForm':
          const form = node.data.target as ISignupForm;
          return <OptinForm form={form} />;
        case 'paragraph':
          const paragraph = node.data.target as IParagraph;
          return <OptinParagraph paragraph={paragraph} />;
        case 'threeColumnsLayout':
          const content = node.data.target as IThreeColumnsLayout;
          return <ColumnLayout data={content} />;
        default:
          return <pre>{contentId}</pre>;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.fields.file.url.indexOf('images') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <img
              className={'w-full md:w-2/3 items-center'}
              src={node.data.target.fields.file.url}
            />
          </div>
        );
      } else if (node.data.target.fields.file.url.indexOf('videos') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <video
              autoPlay={false}
              controls={true}
              loop={false}
              controlsList="nodownload"
            >
              <source src={node.data.target.fields.file.url} />
            </video>
          </div>
        );
      } else if (node.data.target.fields.file.url.indexOf('mp3') != -1) {
        return (
          <div className={'w-full flex items-center justify-center'}>
            <audio
              autoPlay={false}
              controls={true}
              loop={false}
              controlsList="nodownload"
            >
              <source src={node.data.target.fields.file.url} />
            </audio>
          </div>
        );
      } else {
        return (
          <>
            <p>BLOCKS.EMBEDDED_ASSET</p>
            {JSON.stringify(node)}
            {JSON.stringify(children)}
          </>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className={'p-4 text-center text-lg'}>{children}</p>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1
        className={
          'font-montserrat font-black xs:text-3xl sm:text-3xl md:text-5xl text-[#42b99f] text-center my-6 drop-shadow-lg mx-2'
        }
      >
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2
        className={
          'font-montserrat xs:text-2xl sm:text-2xl md:text-4xl text-[#0a3d63] text-center mb-8 font-light mx-4'
        }
      >
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3
        className={
          'font-montserrat xs:text-xl sm:text-xl md:text-2xl font-regular text-center text-[#0a3d63] mx-4'
        }
      >
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4
        className={
          'my-3 font-montserrat text-xl font-base text-center text-[#0a3d63] mx-4'
        }
      >
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5
        className={
          'my-3 font-montserrat text-lg font-base text-center text-[#0a3d63] mx-4'
        }
      >
        {children}
      </h5>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className={'list-decimal'}>{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className={'list-disc'}>{children}</ul>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className={'list-disc'}>{children}</ul>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <div className={'bg-blue-900 text-base text-white'}>{children}</div>
    ),
  },
};

const OptinPage = (): JSX.Element => {
  const { vdi } = useSearchParams<{ vdi: string }>();

  const dispatch = useDispatch();
  const loading = useAppSelector((state) => state.user.isLoading);
  const { slug } = useParams<{ slug: string }>();
  const [data, setData] = useState<IOptinPageFields | undefined>();

  useEffect(() => {
    const { items } = contentfulClient
      .getEntries<IOptinPageFields>({
        content_type: 'optinPage',
        'fields.slug': slug,
        include: 6,
      })
      .then(({ items }) => {
        setData(items[0].fields);
      });
  }, [slug]);

  useEffect(() => {
    if (vdi) {
      Instance(false)
        .request.get('/v1/vdis/' + vdi)
        .then(({ data }) => {
          Cookies.set('_rj_vdi_token', data.data.cookie, {
            domain: process.env.COOKIE_DOMAIN,
            expires: addYears(new Date(), 1),
            sameSite: 'Lax',
          });
        });
    }
  }, [vdi]);

  return (
    <div className={'sm:overflow-y-visible'}>
      {data && data.showHeader && (
        <header
          className={
            'bg-gradient-to-r to-blue-900 from-[#0a3d63] text-white flex items-center justify-center'
          }
        >
          <Logo className={'h-20 m-1.5'} />
        </header>
      )}
      <main className={'my-12'}>
        {data && documentToReactComponents(data.content, optinStyleOptions)}
      </main>
      {data && data.showFooter && (
        <footer
          className={
            'bg-gradient-to-l to-blue-900 from-[#0a3d63] text-white text-sm flex items-center justify-between px-2'
          }
        >
          <Logo className={'h-20 m-1.5'} />
          <span>
            <a href={'/legal/mentions'} target={'_blank'} rel="noreferrer">
              Mentions légales
            </a>{' '}
            -{' '}
            <a href={'/legal/mentions'} target={'_blank'} rel="noreferrer">
              Conditions générales de vente
            </a>
          </span>
          <span>© Rester Jeune 2024 - Tout droits réservés</span>
        </footer>
      )}
    </div>
  );
};

export default OptinPage;
