export enum UserRole {
  prospect = 'prospect',
  banned = 'banned',
  deactived = 'deactivated',
  free = 'free',
  freeUser = 'freeUser',
  fullAccess = 'fullAccess',
  clubMember = 'clubMember',
  paying = 'paying',
  teamMember = 'teamMember',
  admin = 'admin',
  superAdmin = 'Super Admin',
}

const limitedRoles = [UserRole.prospect, UserRole.banned, UserRole.deactived];

const premiumRoles = [
  UserRole.fullAccess,
  UserRole.clubMember,
  UserRole.teamMember,
  UserRole.admin,
  UserRole.superAdmin,
  UserRole.paying,
];

const teamRoles = [UserRole.teamMember, UserRole.admin, UserRole.superAdmin];

const adminRoles = [UserRole.admin, UserRole.superAdmin];
const superAdminRoles = [UserRole.superAdmin];

export { limitedRoles, premiumRoles, teamRoles, adminRoles, superAdminRoles };
